import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../../../../../store/rootReducer";
import {
  flowValueDecisionMaking,
  fuelValueDecisionMaking,
} from "../../../../../../store/DecisionMaking/actions";

import {
  FuelLimits,
  FlowLimits,
} from "../../../../../../models/OptimizationData";

import { getPriorizationRealTime } from "../../../../../../api";

import { ErrorAlert } from "../../../../../../components/ErrorAlert";
import { GradientButton } from "../../../../../../components/GradientButton";

import { Container, Title, Table, Buttons, Input } from "./styles";

import { PriorizationModal } from "../../../../../../components/PriorizationModal";
import { Box, Modal } from "@material-ui/core";

const style = {
  width: "100%",
  height: "100%",
  bgcolor: "#3A3A3A",
  overflow: "hidden auto",
  p: 4,
};

interface LimitOptimizationProps {
  componentName: string;
  handleTab: (value: string) => void;
}

export const LimitOptimization = ({
  componentName,
  handleTab,
}: LimitOptimizationProps) => {
  const dispatch = useDispatch();

  const { fuelValue, flowValue, pciCoeficient } = useSelector(
    (state: AppState) => state.decisionMaking
  );

  const [fuelLimits, setFuelLimits] = useState<FuelLimits[]>(fuelValue);
  const [flowLimits, setFlowLimits] = useState<FlowLimits[]>(flowValue);

  const [showAlert, setShowAlert] = useState(false);
  const [showPriorization, setShowPriorization] = useState(false);
  const [priorizationData, setPriorizationData] = useState([]);

  const handleFuelLimits = (
    value: number,
    lower1: number,
    upper1: number,
    lower2: number,
    upper2: number,
    name: string,
    position: number
  ) => {
    var alerts = handleAlerts(position, value, lower1, upper1, lower2, upper2);
    setFuelLimits(
      fuelLimits.map((item) => {
        return {
          ...item,
          table: item.table.map((item) => {
            return {
              ...item,
              cells: item.cells.map((item) => {
                if (item.name === name) {
                  return {
                    ...item,
                    lower1: position === 1 ? value : lower1,
                    upper1: position === 2 ? value : upper1,
                    lower2: position === 3 ? value : lower2,
                    upper2: position === 4 ? value : upper2,
                    alertLower1: alerts[0],
                    alertUpper1: alerts[1],
                    alertLower2: alerts[2],
                    alertUpper2: alerts[3],
                  };
                }
                return item;
              }),
            };
          }),
        };
      })
    );
  };

  const handleMinFlowLimits = (
    value: number,
    maxValue: number,
    equipment: string
  ) => {
    setFlowLimits(
      flowLimits.map((item1) => {
        return {
          ...item1,
          table: item1.table.map((item2) => {
            if (item2.equipment === equipment) {
              return {
                ...item2,
                lower: value,
                alertLower: value > maxValue,
                alertUpper: false,
              };
            }
            return item2;
          }),
        };
      })
    );
  };

  const handleMaxFlowLimits = (
    value: number,
    minValue: number,
    equipment: string
  ) => {
    setFlowLimits(
      flowLimits.map((item1) => {
        return {
          ...item1,
          table: item1.table.map((item2) => {
            if (item2.equipment === equipment) {
              return {
                ...item2,
                upper: value,
                alertLower: false,
                alertUpper: value < minValue,
              };
            }
            return item2;
          }),
        };
      })
    );
  };

  const handleAlerts = (
    aux: number,
    value: number,
    lower1: number,
    upper1: number,
    lower2: number,
    upper2: number,
  ) => {
    var alerts: boolean[] = [];

    switch (aux) {
      case 1:
        lower1 = value;
        break;
      case 2:
        upper1 = value;
        break;
      case 3:
        lower2 = value;
        break;
      case 4:
        upper2 = value;
        break;
      default:
    }

    alerts[0] = lower1 > upper1 || lower1 > lower2 || lower1 > upper2;
    alerts[1] = upper1 < lower1 || upper1 > lower2 || upper1 > upper2;
    alerts[2] = lower2 < lower1 || lower2 < upper1 || lower2 > upper2;
    alerts[3] = upper2 < lower1 || upper2 < upper1 || upper2 < lower2;

    return alerts;
  };

  const clickOptimizationButton = (tab: string) => {
    if (
      fuelLimits[0].table.every(
        (item) => item.cells.every(
          (item) => !item.alertLower1 && !item.alertUpper1 && !item.alertLower2 && !item.alertUpper2
        )
      ) &&
      flowLimits[0].table.every((item) => !item.alertLower && !item.alertUpper)
    ) {
      handleTab(tab);
      dispatch(fuelValueDecisionMaking(fuelLimits));
      dispatch(flowValueDecisionMaking(flowLimits));
    } else {
      setShowAlert(true);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleClosePriorizationModal = () => {
    setShowPriorization(false);
  };

  const clickPriorizationButton = () => {
    if (
      fuelLimits[0].table.every(
        (item) => item.cells.every(
          (item) => !item.alertLower1 && !item.alertUpper1 && !item.alertLower2 && !item.alertUpper2
        )
      )
    ) {
      dispatch(fuelValueDecisionMaking(fuelLimits));
      const result = [pciCoeficient[0], pciCoeficient[1]];
      getPriorizationRealTime(JSON.stringify(result))
        .then((response) => {
          setPriorizationData(response.data);
          setShowPriorization(true);
        })
        .catch((error) => {
          console.log(error);
          setShowPriorization(false);
        });
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Container>
      <Title>LIMITES</Title>
      <Table>
        <thead>
          <tr>
            <th colSpan={6} className="title-table">
              LIMITE DOS COMBUSTÍVEIS
            </th>
          </tr>
          <tr>
            <th>EQUIPAMENTO</th>
            <th>COMBUSTÍVEL</th>
            <th>LIMITE INFERIOR 1</th>
            <th>LIMITE SUPERIOR 1</th>
            <th>LIMITE INFERIOR 2</th>
            <th>LIMITE SUPERIOR 2</th>
          </tr>
        </thead>
        <tbody>
          {fuelLimits.map((item) =>
            item.table.map((itemTable) =>
              itemTable.cells.map((cell) => (
                <tr key={cell.equipment + cell.fuel}>
                  {cell.fuel === "GAC" && <td rowSpan={5}>{cell.equipment}</td>}
                  <td>{cell.fuel === "OL" ? "Óleo" : cell.fuel}</td>
                  <td className="tdInput">
                    <Input
                      type="number"
                      error={cell.alertLower1}
                      defaultValue={cell.lower1}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleFuelLimits(value, cell.lower1, cell.upper1, cell.lower2, cell.upper2, cell.name, 1);
                      }}
                    />
                    <span>{cell.unit}</span>
                  </td>
                  <td className="tdInput">
                    <Input
                      type="number"
                      error={cell.alertUpper1}
                      defaultValue={cell.upper1}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleFuelLimits(value, cell.lower1, cell.upper1, cell.lower2, cell.upper2, cell.name, 2);
                      }}
                    />
                    <span>{cell.unit}</span>
                  </td>
                  <td className="tdInput2">
                    <Input
                      type="number"
                      error={cell.alertLower2}
                      defaultValue={cell.lower2}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleFuelLimits(value, cell.lower1, cell.upper1, cell.lower2, cell.upper2, cell.name, 3);
                      }}
                    />
                    <span>{cell.unit}</span>
                  </td>
                  <td className="tdInput2">
                    <Input
                      type="number"
                      error={cell.alertUpper2}
                      defaultValue={cell.upper2}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleFuelLimits(value, cell.lower1, cell.upper1, cell.lower2, cell.upper2, cell.name, 4);
                      }}
                    />
                    <span>{cell.unit}</span>
                  </td>
                </tr>
              ))
            )
          )}
        </tbody>
      </Table>

      <Table>
        <thead>
          <tr>
            <th colSpan={4} className="title-table">
              LIMITES DAS VAZÕES DE VAPOR TOTAL DAS CALDEIRAS
            </th>
          </tr>
          <tr>
            <th>EQUIPAMENTO</th>
            <th>LIMITE INFERIOR</th>
            <th>LIMITE SUPERIOR</th>
          </tr>
        </thead>
        <tbody>
          {flowLimits.map((item) =>
            item.table.map((itemTable, index) => (
              <tr key={index}>
                <td>{itemTable.equipment}</td>
                <td>
                  <Input
                    type="number"
                    error={itemTable.alertLower}
                    defaultValue={itemTable.lower}
                    onChange={(e) =>
                      handleMinFlowLimits(
                        Number(e.target.value),
                        itemTable.upper,
                        itemTable.equipment
                      )
                    }
                  />
                  <span>{itemTable.unit}</span>
                </td>
                <td>
                  <Input
                    type="number"
                    error={itemTable.alertUpper}
                    defaultValue={itemTable.upper}
                    onChange={(e) =>
                      handleMaxFlowLimits(
                        Number(e.target.value),
                        itemTable.lower,
                        itemTable.equipment
                      )
                    }
                  />
                  <span>{itemTable.unit}</span>
                </td>
              </tr>
            ))
          )}
          <tr>
            <td>Máxima Diferença entre as Caldeiras</td>
            <td colSpan={2}>
              <Input
                type="number"
                error={false}
                defaultValue={flowLimits[0].maxDifference[0].value}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setFlowLimits(
                    flowLimits.map((item) => {
                      return {
                        ...item,
                        maxDifference: item.maxDifference.map((item) => {
                          return {
                            ...item,
                            value: value,
                          }
                        }),
                      }
                    }
                    )
                  );
                }}
              />
              <span>{flowLimits[0].maxDifference[0].unit}</span>
            </td>
          </tr>
        </tbody>
      </Table>

      <Buttons>
        <GradientButton onClick={() => handleTab("inicio")}>
          Voltar
        </GradientButton>

        <div>
          <GradientButton onClick={() => clickPriorizationButton()}>
            PRIORIZAÇÃO
          </GradientButton>
          <GradientButton
            onClick={() => clickOptimizationButton("otimização-demanda")}
          >
            OTIMIZAÇÃO POR DEMANDA
          </GradientButton>
          <GradientButton
            onClick={() => clickOptimizationButton("otimização-tempo-real")}
          >
            OTIMIZAÇÃO EM TEMPO REAL
          </GradientButton>
        </div>
      </Buttons>

      {showAlert && (
        <ErrorAlert
          open={showAlert}
          setOpenAlert={handleAlertClose}
          message="O limite superior deve ser maior que o limite inferior e o intervalo 2 deve ser maior que o intervalo 1.
          Por favor verifique os campos em vermelho."
        />
      )}

      <Modal open={showPriorization} onClose={handleClosePriorizationModal}>
        <Box sx={style}>
          <PriorizationModal data={priorizationData} handleClose={handleClosePriorizationModal} />
        </Box>
      </Modal>
    </Container>
  );
};
