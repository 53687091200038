import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@material-ui/core";

import { getCoeficient, getCoeficientAndPCI, getPCI } from "../../api";

import { TableDataDecisionMaking } from "../../models/TableData";

import { pciCoeficientValueDecisionMaking } from "../../store/DecisionMaking/actions";
import { AppState } from "../../store/rootReducer";

import { Checkbox, Container, Table, Tables, Title } from "./styles";

interface TablesDecisionMaking1Props {
  handleLoader: (value: boolean) => void;
  loader2: boolean;
}

export const TablesDecisionMaking1 = ({
  handleLoader,
  loader2,
}: TablesDecisionMaking1Props) => {
  const dispatch = useDispatch();

  const { pciCoeficient, date, calculateItems } = useSelector(
    (state: AppState) => state.decisionMaking
  );

  const { coef, pci } = calculateItems;

  const [loader, setLoader] = useState(false);

  const [pciReq, setPciReq] = useState<TableDataDecisionMaking[]>([]);
  const [coeficientReq, setCoeficientReq] = useState<TableDataDecisionMaking[]>(
    []
  );

  const [pciCoeficientUpdated, setPciCoeficientUpdated] =
    useState<TableDataDecisionMaking[]>(pciCoeficient);

  useEffect(() => {
    if (loader === false) {
      setPciCoeficientUpdated(changeFalse(pciCoeficientUpdated, pciCoeficient));
    }
  }, [loader, pciCoeficient]);

  const handleChangeChecked = (value: number, profile: string, state: boolean) => {
    const updated = pciCoeficientUpdated.map(({ title, table }) => ({
      title,
      table: table.map(({ name, cells }) => ({              
        name,
        cells: cells.map((cell) => {                                                                                                                                                                                           
          if (profile === cell.name.split("_")[0]) {                                                   
            const result = { ...cell, value: value, state: state }
            return result
          } else {
            return cell
          }
        }
        ),
      })),
    }));
    setPciCoeficientUpdated(updated);
  };

  function setInitialState(
    matriz: TableDataDecisionMaking[]
  ): TableDataDecisionMaking[] {
    return matriz.map(({ title, table }) => ({
      title,
      table: table.map(({ name, cells }) => ({
        name,
        cells: cells.map((cell) => ({
          ...cell,
          state: true,
        })),
      })),
    }));
  }

  function getValueByName(matriz: TableDataDecisionMaking[], profile: string): number {
    let foundValue: number | undefined;

    matriz.forEach(({ table }) => {
      table.forEach(({ cells }) => {
        cells.forEach((cell) => {
          if (cell.name === profile) {
            foundValue = cell.value;
          }
        });
      });
    });

    if (foundValue !== undefined) {
      return foundValue;
    } else {
      return 0;
    }
  }

  function changeFalse(
    matrizpciCoeficientUpdated: TableDataDecisionMaking[],
    matrizpciCoeficient: TableDataDecisionMaking[]
  ): TableDataDecisionMaking[] {
    return matrizpciCoeficientUpdated.map(({ title, table }) => ({
      title,
      table: table.map(({ name, cells }) => ({
        name,
        cells: cells.map((cell) => {
          if (cell.state === false) {
            const value = getValueByName(matrizpciCoeficient, cell.name);
            const result = { ...cell, value };
            return result;
          } else {
            return cell;
          }
        }),
      })),
    }));
  }

  useEffect(() => {
    if (loader2) {
      const finalPciCoef = pciCoeficientUpdated.map(({ title, table }) => ({
        title,
        table: table.map(({ name, cells }) => ({
          name,
          cells: cells.map(({ name, value }) => ({
            name,
            value: value === null ? 0 : value,
          })),
        })),
      }));
      dispatch(pciCoeficientValueDecisionMaking(finalPciCoef));
    }
  }, [dispatch, loader2, pciCoeficientUpdated]);

  useEffect(() => {
    setPciCoeficientUpdated([setInitialState(pciReq)[0], setInitialState(coeficientReq)[0]]);
  }, [coeficientReq, pciReq]);

  useEffect(() => {
    setLoader(true);

    if (coef === true && pci === false) {
      getCoeficient(date.initial, date.final).then((response) => {
        setCoeficientReq(response.data);
        setLoader(false);
      });
    } else if (coef === false && pci === true) {
      getPCI(date.initial, date.final).then((response) => {
        setPciReq(response.data);
        setLoader(false);
      });
    } else if (coef === true && pci === true) {
      getCoeficientAndPCI(date.initial, date.final).then((response) => {
        setCoeficientReq([response.data[1]]);
        setPciReq([response.data[0]]);
        setLoader(false);
      });
    }
  }, [coef, pci, date]);

  useEffect(() => {
    handleLoader(loader);
  }, [loader, handleLoader]);

  const createRowFuelPCI = (cells: any) => {
    return (
      <>
        <th key={cells[2].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[2].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(Number(cells[2].value), cells[2].name, true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name === cells[2].name &&
                          handleChangeChecked(value, name, false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[2].value === null ? "-" : Number(cells[2].value).toFixed()}
            </p>
          </Checkbox>
        </th>
        <th key={cells[1].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[1].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(Number(cells[1].value), cells[1].name, true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name === cells[1].name &&
                          handleChangeChecked(value, name, false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[1].value === null ? "-" : Number(cells[1].value).toFixed()}
            </p>
          </Checkbox>
        </th>
        <th key={cells[0].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[0].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(Number(cells[0].value), cells[0].name, true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name === cells[0].name &&
                          handleChangeChecked(value, name, false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[0].value === null ? "-" : Number(cells[0].value).toFixed()}
            </p>
          </Checkbox>
        </th>
        <th key={cells[3].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[3].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(Number(cells[3].value), cells[3].name, true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name.replace('Oleo', 'OL') === cells[3].name &&
                          handleChangeChecked(value, name.replace('Oleo', 'OL'), false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[3].value === null ? "-" : Number(cells[3].value).toFixed()}
            </p>
          </Checkbox>
        </th>
        <th key={cells[4].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[4].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(Number(cells[4].value), cells[4].name, true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name === cells[4].name &&
                          handleChangeChecked(value, name, false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[4].value === null ? "-" : Number(cells[4].value).toFixed()}
            </p>
          </Checkbox>
        </th>
      </>
    );
  };

  const createRowFuelCoef = (cells: any) => {
    return (
      <>
        <th key={cells[2].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[2].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(cells[2].value, cells[2].name.split("_")[0], true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name.split("_")[0] === cells[2].name?.split("_")[0] &&
                          handleChangeChecked(value, name.split("_")[0], false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[2].value === null ? "-" : Number(cells[2].value).toFixed(3)}
            </p>
          </Checkbox>
        </th>
        <th key={cells[1].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[1].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(cells[1].value, cells[1].name.split("_")[0], true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name.split("_")[0] === cells[1].name.split("_")[0] &&
                          handleChangeChecked(value, name.split("_")[0], false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[1].value === null ? "-" : Number(cells[1].value).toFixed(3)}
            </p>
          </Checkbox>
        </th>
        <th key={cells[0].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[0].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(cells[0].value, cells[0].name.split("_")[0], true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name.split("_")[0] === cells[0].name.split("_")[0] &&
                          handleChangeChecked(value, name.split("_")[0], false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[0].value === null ? "-" : Number(cells[0].value).toFixed(3)}
            </p>
          </Checkbox>
        </th>
        <th key={cells[3].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[3].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(cells[3].value, cells[3].name.split("_")[0], true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name.replace('Oleo', 'OL').split("_")[0] === cells[3].name.split("_")[0] &&
                          handleChangeChecked(value, name.replace('Oleo', 'OL').split("_")[0], false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[3].value === null ? "-" : Number(cells[3].value).toFixed(3)}
            </p>
          </Checkbox>
        </th>
        <th key={cells[4].name}>
          <Checkbox>
            <input
              type="checkbox"
              id={cells[4].name}
              defaultChecked
              onClick={(e) => {
                if (e.currentTarget.checked) {
                  handleChangeChecked(cells[4].value, cells[4].name.split("_")[0], true);
                } else {
                  pciCoeficient.map(({ title, table }) => ({
                    title,
                    table: table.map(({ name, cells: lowerCells }) => ({
                      name,
                      cells: lowerCells.map(({ name, value }) => {
                        name.split("_")[0] === cells[4].name.split("_")[0] &&
                          handleChangeChecked(value, name.split("_")[0], false);
                        return 0;
                      }),
                    })),
                  }));
                }
              }}
            />
            <p>
              {cells[4].value === null ? "-" : Number(cells[4].value).toFixed(3)}
            </p>
          </Checkbox>
        </th>
        <th key={cells[5].name}>
          <p>
            {cells[5].value === null ? "-" : Number(cells[5].value).toFixed(3)}
          </p>
        </th>
      </>
    );
  };

  if (loader) {
    return (
      <Container>
        <CircularProgress
          style={{
            color: "#4CA6A8",
            margin: "0 auto",
            marginTop: "50px",
            marginBottom: "50px",
          }}
          size={50}
        />
      </Container>
    );
  }

  return (
    <Tables>
      {pciReq.map((table, index) => (
        <Container key={index}>
          <Title>PCI</Title>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>GCO</th>
                <th>GAF</th>
                <th>GAC</th>
                <th>Oleo</th>
                <th>GN</th>
              </tr>
            </thead>
            <tbody>
              {table.table.map((combustivel, index) => (
                <tr key={index}>
                  <th>{combustivel.name}</th>
                  {createRowFuelPCI(combustivel.cells)}
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      ))}
      {coeficientReq.map((table, index) => (
        <Container key={index}>
          <Title>COEFICIENTE</Title>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>GCO</th>
                <th>GAF</th>
                <th>GAC</th>
                <th>Oleo</th>
                <th>GN</th>
                <th>R²</th>
              </tr>
            </thead>
            <tbody>
              {table.table.map((combustivel, index) => (
                <tr key={index}>
                  <th>{combustivel.name}</th>
                  {createRowFuelCoef(combustivel.cells)}
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      ))}
    </Tables>
  );
};
