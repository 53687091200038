import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { getOptimizationRealTime } from "../../api";

import { AppState } from "../../store/rootReducer";

import LogoImg from "../../assets/nexumtwin.png";

import {
  Container,
  ButtonClose,
  Title,
  Table,
  TitleTable,
  Grid,
  Table2,
  Infos,
  Info,
  TitleFailure,
  ContainerFailure,
  Logo,
  PosOptmizationMessage,
} from "./styles";

type Data = {
  atual: AtualData;
  validation_model: boolean;
  optimization: Optimization;
  alarm: Alarm[];
  model: Model;
}

type AtualData = {
  variables: Variable[];
  f_total: FTotal[];
  f_boilers: FBoiler[];

}

type Variable = {
  name: string;
  value: string;
  lower?: string;
  upper?: string;
}

type FTotal = {
  unit: string;
  name: string;
  value: string;
}

type FBoiler = {
  unit: string;
  values: BoilerValues;
  name: string;
}

type BoilerValues = {
  [boiler: string]: string;
}

type Optimization = {
  f_boilers: FBoiler[];
  f_fuels: FFuel[];
  f_total: FTotal[];
  info: Info;
  pos_validation: PosValidation;
  validation_model: boolean;
  variables: Variable[];
}

type FFuel = {
  name: string;
  unit: string;
  values: FuelValues;
}

type FuelValues = {
  [fuel: string]: string;
}

type Info = {
  bounds_violation: boolean;
  constraints_message: string;
  method: string;
  number_of_evaluations: number;
  number_of_iterations: number;
  optimization_message: string;
  solution_message: string;
  status: number;
  success: boolean;
}

type PosValidation = {
  message: string[];
  status: boolean;
}

type Alarm = {
  color: string;
  name: string;
  diff: number;
  colorHex: string;
}

type Model = {
  title: string;
  table: TableData[];
}

type TableData = {
  name: string;
  value: string;
}

interface RealTimeOptimizationModalProps {
  handleClose: () => void;
  data: any[];
}

export const RealTimeOptimizationModal = ({
  handleClose,
  data,
}: RealTimeOptimizationModalProps) => {
  const [realTimeOptimization, setRealTimeOptimization] = useState<Data[]>(data);

  const { pciCoeficient, fuelValue, flowValue, fuelReal } = useSelector(
    (state: AppState) => state.decisionMaking
  );

  useEffect(() => {
    const pciCoefFinal = pciCoeficient.map((item) => {
      if (item.title === "PCI") {
        return {
          ...item,
          table: item.table.map((item) => {
            return {
              ...item,
              cells: item.cells.map((item) => {
                return {
                  ...item,
                  name: item.name.includes("C4")
                    ? item.name.replace("OleoGN", "GN")
                    : item.name.replace("OleoGN", "Oleo"),
                };
              }),
            };
          }),
        };
      } else {
        return {
          ...item,
          table: item.table.map((item) => {
            return {
              ...item,
              cells: item.cells
                .map((item) => {
                  return {
                    ...item,
                    name: item.name.includes("C4")
                      ? item.name.replace("OleoGN", "GN")
                      : item.name.replace("OleoGN", "Oleo"),
                  };
                })
                .slice(0, item.cells.length - 1),
            };
          }),
        };
      }
    });

    const fuelValueFinal = fuelValue.map((item) => {
      return {
        ...item,
        table: item.table.map((item) => {
          return {
            ...item,
            cells: item.cells.map(({ name, lower1, upper1, lower2, upper2 }) => {
              return {
                name,
                lower1,
                upper1,
                lower2,
                upper2,
              };
            }),
          };
        }),
      };
    });

    const flowValueFinal = flowValue.map((item) => {
      return {
        ...item,
        table: item.table.map(({ name, upper, lower, considered }) => {
          return {
            name,
            upper,
            lower,
            considered
          };
        }),
      };
    });

    const fuelRealFinal = fuelReal.map((item) => {
      return {
        ...item,
        table: item.table.map(({ name, upper, lower }) => {
          return {
            name,
            upper,
            lower,
          };
        }),
      };
    });

    const result = [
      pciCoefFinal[0],
      pciCoefFinal[1],
      fuelValueFinal[0],
      flowValueFinal[0],
      fuelRealFinal[0],
    ];

    const requestInterval = setInterval(() => {
      getOptimizationRealTime(JSON.stringify(result))
        .then((response) => {
          setRealTimeOptimization(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 60000);

    return () => { clearInterval(requestInterval) }
  }, [flowValue, fuelReal, fuelValue, pciCoeficient]);

  const countdownSpinner = () => {
    return (
      <>
        <svg height="0px" width="0px">
          <defs>
            <linearGradient id="linear-gradient-id" x1="1" y1="0" x2="0" y2="0">
              <stop offset="5%" stopColor="#009EE2" />
              <stop offset="95%" stopColor="#19B0A0" />
            </linearGradient>
          </defs>
        </svg>
        <CountdownCircleTimer
          isPlaying
          duration={60}
          colors="url(#linear-gradient-id)"
          onComplete={() => {
            return { shouldRepeat: true }
          }}
          size={50}
          strokeWidth={6}
        >
          {({ remainingTime }) => (
            <span style={{ color: "#d9d9d9" }}>
              {remainingTime}
            </span>
          )}
        </CountdownCircleTimer>
      </>
    )
  };

  const variablesDisponibilityFuels = (name: string) => {
    return realTimeOptimization.map((item) =>
      item.atual.variables.map(
        (item: any) =>
          item.name.includes(name) && (
            <>
              <td>{item.lower.replace(".", ",")}</td>
              <td>{item.value.replace(".", ",")}</td>
              <td>{item.upper.replace(".", ",")}</td>
            </>
          )
      )
    );
  };

  const backgroundColor = (number: number) => {
    if (number === -1) {
      const last = realTimeOptimization[0].alarm.length - 1;
      return realTimeOptimization[0].alarm[last]?.colorHex;
    }

    return realTimeOptimization[0].alarm[number]?.colorHex;
  };

  const variablesDistributionFuels = (name: string) => {
    const abbreviations = name.split('/')
    const name1 = abbreviations[0]
    const name2 = abbreviations.length === 2 ? abbreviations[1] : "NA"

    return (
      <tr>
        <td>{name}</td>
        {
          realTimeOptimization[0].atual.variables.filter(atual => (atual.name.includes(name1) || atual.name.includes(name2))).map(item => {
            const bg = realTimeOptimization[0].alarm.find(itemBg => itemBg.name.replaceAll("_", "").toLowerCase() === item.name.replaceAll("_", "").toLowerCase())?.colorHex
            return (
              <>
                <td style={{ background: bg ? bg : "transparent" }} >
                  {item.value.replace(".", ",")}
                </td>
                <td>
                  {
                    realTimeOptimization[0].optimization.variables.find(optmization => optmization.name.toLowerCase() === item.name.toLowerCase())?.value ?
                      realTimeOptimization[0].optimization.variables.find(optmization => optmization.name.toLowerCase() === item.name.toLowerCase())?.value :
                      "0,00"
                  }
                </td>
              </>
            )
          })
        }
      </tr>
    );
  };

  const variablesTrConstruction = (name: string, number: number) => {
    return (
      <tr>
        <td>{name}</td>
        <td>
          {realTimeOptimization[0].atual.f_boilers[number].values.C1?.replace(".", ","
          )}
        </td>
        <td>
          {realTimeOptimization[0].optimization.f_boilers[number].values.C1?.replace(".", ",") || "0,00"}
        </td>
        <td>
          {realTimeOptimization[0].atual.f_boilers[number].values.C2?.replace(".", ",")}
        </td>
        <td>
          {realTimeOptimization[0].optimization.f_boilers[number].values.C2?.replace(".", ",") || "0,00"}
        </td>
        <td>
          {realTimeOptimization[0].atual.f_boilers[number].values.C3?.replace(".", ",")}
        </td>
        <td>
          {realTimeOptimization[0].optimization.f_boilers[number].values.C3?.replace(".", ",") || "0,00"}
        </td>
        <td>
          {realTimeOptimization[0].atual.f_boilers[number].values.C4?.replace(".", ",")}
        </td>
        <td>
          {realTimeOptimization[0].optimization.f_boilers[number].values.C4?.replace(".", ",") || "0,00"}
        </td>
        <td>
          {realTimeOptimization[0].atual.f_total[number].value.replace(".", ",")}
        </td>
        <td>
          {realTimeOptimization[0].optimization.f_total[number].value.replace(".", ",")}
        </td>
      </tr>
    );
  };

  const constructionInfos = (
    name: string,
    number: number,
    background: string,
    color: string
  ) => {
    return (
      <Info>
        <h1>{name}</h1>
        <div
          style={{
            background: background,
          }}
        >
          <p
            style={{
              color: color,
            }}
          >
            Atual:{" "}
            {realTimeOptimization[0].atual.f_total[number].value.replace(
              ".",
              ","
            )}{" "}
            {realTimeOptimization[0].atual.f_total[number].unit}
          </p>
        </div>
        <div>
          <p>
            Otimizado:{" "}
            {realTimeOptimization[0].optimization.f_total[number].value.replace(
              ".",
              ","
            )}{" "}
            {realTimeOptimization[0].optimization.f_total[number].unit}
          </p>
        </div>
      </Info>
    );
  };

  const boilerDifferenceIdentifier = () => {
    let productionValues: any = [];

    productionValues.push(
      parseFloat(realTimeOptimization[0].atual.f_boilers[1].values.C1)
    );
    productionValues.push(
      parseFloat(realTimeOptimization[0].atual.f_boilers[1].values.C2)
    );
    productionValues.push(
      parseFloat(realTimeOptimization[0].atual.f_boilers[1].values.C3)
    );
    productionValues.push(
      parseFloat(realTimeOptimization[0].atual.f_boilers[1].values.C4)
    );
    const modelValues = () =>
      realTimeOptimization[0].model.table.map((item: any) =>
        parseFloat(item.value)
      );

    const differenceIdentifier = (value1: any, value2: any) => {
      const differenceArray: any = [];
      for (let i = 0; 3 >= i; i++) {
        let difference: any = value1[i] - value2[i];
        differenceArray.push(parseFloat(difference.toFixed(3)));
      }
      return differenceArray;
    };
    const modelRealDifference = differenceIdentifier(
      modelValues(),
      productionValues
    );
    const checkColor = (array: any) => {
      const colorsArray: any = [];
      for (let i = 0; i < array.length; i++) {
        let current = array[i];
        if (current < 0) {
          current = current * -1;
        }
        if (current <= 5) {
          colorsArray.push("#007C05");
        } else if (current <= 8) {
          colorsArray.push("#BFA519");
        } else {
          colorsArray.push("#FF0000");
        }
      }
      return colorsArray;
    };
    const colors = checkColor(modelRealDifference);
    return colors;
  };

  const colors = boilerDifferenceIdentifier();

  if (
    realTimeOptimization[0].optimization.info.solution_message ===
    "Infeasible solution"
  ) {

    return (
      <ContainerFailure>
        <div>
          <Logo>
            <img src={LogoImg} alt="" />
          </Logo>
          <Title>OPERAÇÃO EM TEMPO REAL</Title>
          <div>
            {countdownSpinner()}
            <ButtonClose onClick={() => handleClose()}>X</ButtonClose>
          </div>
        </div>

        {realTimeOptimization[0].optimization.pos_validation.message.map((message, index) => (
          <PosOptmizationMessage key={index}>
            {message}
          </PosOptmizationMessage>
        ))}
      </ContainerFailure>
    );
  }

  return (
    <Container>
      <div>
        <Logo>
          <img src={LogoImg} alt="" />
        </Logo>
        <Title>OPERAÇÃO EM TEMPO REAL</Title>
        <div>
          {countdownSpinner()}
          <ButtonClose onClick={() => handleClose()}>X</ButtonClose>
        </div>
      </div>

      <Table>
        <TitleTable>DISTRIBUIÇÃO DAS VAZÕES DE COMBUSTÍVEIS</TitleTable>
        <table>
          <thead>
            <tr>
              <th rowSpan={2}>Combustível</th>
              <th colSpan={2} className="no-border">
                Caldeira 1
              </th>
              <th colSpan={2} className="no-border">
                Caldeira 2
              </th>
              <th colSpan={2} className="no-border">
                Caldeira 3
              </th>
              <th colSpan={2} className="no-border">
                Caldeira 4
              </th>
              <th colSpan={2} className="no-border">
                Total
              </th>
            </tr>
            <tr>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
            </tr>
          </thead>
          <tbody>
            {variablesDistributionFuels("GCO")}
            {variablesDistributionFuels("GAF")}
            {variablesDistributionFuels("GAC")}
            {variablesDistributionFuels("OL")}
            {variablesDistributionFuels("GN")}
          </tbody>
        </table>
      </Table>

      <Table>
        <TitleTable>
          ENERGIA | CONSUMO ESPECÍFICO | PRODUÇÃO DE VAPOR
        </TitleTable>
        <table>
          <thead>
            <tr>
              <th rowSpan={2}></th>
              <th colSpan={2} className="no-border">
                Caldeira 1
              </th>
              <th colSpan={2} className="no-border">
                Caldeira 2
              </th>
              <th colSpan={2} className="no-border">
                Caldeira 3
              </th>
              <th colSpan={2} className="no-border">
                Caldeira 4
              </th>
              <th colSpan={2} className="no-border">
                Total
              </th>
            </tr>
            <tr>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
              <th className="no-border2">Atual</th>
              <th className="no-border2">Otimizado</th>
            </tr>
          </thead>
          <tbody>
            {variablesTrConstruction("ENERGIA [Mcal/h]", 0)}
            {variablesTrConstruction("CONS. ESP. [Mcal/ton]", 2)}
            {variablesTrConstruction("PRODUÇÃO [ton/h]", 1)}
          </tbody>
        </table>
      </Table>

      <Table>
        <table className="sympleTable">
          <tbody>
            <tr>
              <td>PRODUÇÃO PREVISÃO MODELO [ton/h]</td>
              {realTimeOptimization[0].model.table.map((item: any, i: any) => (
                <td style={{ fontWeight: "bold", color: colors[i] }}>
                  {item.value.replace(".", ",")}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Table>

      <Grid>
        <Table2>
          <TitleTable>DISPONIBILIDADE DOS COMBUSTÍVEIS</TitleTable>
          <table>
            <thead>
              <tr>
                <th>Combustível</th>
                <th>Limite Inferior</th>
                <th>Valor Atual</th>
                <th>Limite Superior</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>GCO</td>
                {variablesDisponibilityFuels("GCO_total")}
              </tr>
              <tr>
                <td>GAF</td>
                {variablesDisponibilityFuels("GAF_total")}
              </tr>
              <tr>
                <td>GAC</td>
                {variablesDisponibilityFuels("GAC_total")}
              </tr>
              <tr>
                <td>Óleo</td>
                {variablesDisponibilityFuels("OL_total")}
              </tr>
              <tr>
                <td>GN</td>
                {variablesDisponibilityFuels("GN_total")}
              </tr>
            </tbody>
          </table>
        </Table2>

        <Infos>
          {constructionInfos("ENERGIA TOTAL", 0, "", "")}
          {constructionInfos("PR0DUÇÃO DE VAPOR TOTAL", 1, "", "")}
          {constructionInfos("CONSUMO ESPECÍFICO TOTAL", 2, backgroundColor(-1), "#fff")}
        </Infos>
      </Grid>
    </Container>
  );
};
