export const headerTable = [
  {
    caldeira: "Caldeira 1",
    fuel: ["GCO", "GAF", "GAC", "OLEO", "GN"],
  },
  {
    caldeira: "Caldeira 2",
    fuel: ["GCO", "GAF", "GAC", "OLEO", "GN"],
  },
  {
    caldeira: "Caldeira 3",
    fuel: ["GCO", "GAF", "GAC", "OLEO", "GN"],
  },
  {
    caldeira: "Caldeira 4",
    fuel: ["GCO", "GAF", "GAC", "OLEO", "GN"],
  },
];

export const bodyTable = (dateFinal: any) => {
  return [
    {
      name: "Tempo",
      value: dateFinal[0] + ":" + dateFinal[1],
    },
    {
      name: "C1VAZAOGCO",
      value: "0",
    },
    {
      name: "C1VAZAOGAF",
      value: "0",
    },
    {
      name: "C1VAZAOGAC",
      value: "0",
    },
    {
      name: "C1VAZAOOLEO",
      value: "0",
    },
    {
      name: "C1VAZAOGN",
      value: "0",
    },
    {
      name: "C2VAZAOGCO",
      value: "0",
    },
    {
      name: "C2VAZAOGAF",
      value: "0",
    },
    {
      name: "C2VAZAOGAC",
      value: "0",
    },
    {
      name: "C2VAZAOOLEO",
      value: "0",
    },
    {
      name: "C2VAZAOGN",
      value: "0",
    },
    {
      name: "C3VAZAOGCO",
      value: "0",
    },
    {
      name: "C3VAZAOGAF",
      value: "0",
    },
    {
      name: "C3VAZAOGAC",
      value: "0",
    },
    {
      name: "C3VAZAOOLEO",
      value: "0",
    },
    {
      name: "C3VAZAOGN",
      value: "0",
    },
    {
      name: "C4VAZAOGCO",
      value: "0",
    },
    {
      name: "C4VAZAOGAF",
      value: "0",
    },
    {
      name: "C4VAZAOGAC",
      value: "0",
    },
    {
      name: "C4VAZAOOLEO",
      value: "0",
    },
    {
      name: "C4VAZAOGN",
      value: "0",
    },
  ];
};

export const defaultValueDateScenery = (value: string) => {
  const finalValue =
    value.split(" ")[0].split("/").reverse().join("-") +
    "T" +
    value.split(" ")[1];

  return finalValue;
};

export const formatDateScenery = (value: string) => {
  const newTime = new Date(value);
  const data = new Date(newTime);
  const finalTime =
    ("0" + data.getDate()).slice(-2) +
    "/" +
    ("0" + (data.getMonth() + 1)).slice(-2) +
    "/" +
    data.getFullYear() +
    " " +
    ("0" + data.getHours()).slice(-2) +
    ":" +
    ("0" + data.getMinutes()).slice(-2);

  return finalTime;
};

export const commaToDot = (value: string) => {
  return value.replace(",", ".");
};
