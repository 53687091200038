export const fuelRealLimitsInitial = [
  {
    title: "FUELS",
    table: [
      {
        name: "GAC",
        fuel: "GAC",
        unit: "kNm³/h",
        lower: 1,
        upper: 1,
        alertLower: false,
        alertUpper: false,
      },
      {
        name: "GAF",
        fuel: "GAF",
        unit: "kNm³/h",
        lower: 5,
        upper: 5,
        alertLower: false,
        alertUpper: false,
      },
      {
        name: "GCO",
        fuel: "GCO",
        unit: "kNm³/h",
        lower: 1,
        upper: 1,
        alertLower: false,
        alertUpper: false,
      },
      {
        name: "OL",
        fuel: "OL",
        unit: "ton/h",
        lower: 1,
        upper: 1,
        alertLower: false,
        alertUpper: false,
      },
      {
        name: "GN",
        fuel: "GN",
        unit: "kNm³/h",
        lower: 1,
        upper: 1,
        alertLower: false,
        alertUpper: false,
      },
    ],
  },
];

export const fuelDemandLimitsInitial = [
  {
    title: "FUELS",
    table: [
      {
        name: "GAC",
        fuel: "GAC",
        unit: "kNm³/h",
        lower: 0,
        upper: 80,
        alertLower: false,
        alertUpper: false,
      },
      {
        name: "GAF",
        fuel: "GAF",
        unit: "kNm³/h",
        lower: 0,
        upper: 480,
        alertLower: false,
        alertUpper: false,
      },
      {
        name: "GCO",
        fuel: "GCO",
        unit: "kNm³/h",
        lower: 0,
        upper: 60,
        alertLower: false,
        alertUpper: false,
      },
      {
        name: "OL",
        fuel: "OL",
        unit: "ton/h",
        lower: 0,
        upper: 48,
        alertLower: false,
        alertUpper: false,
      },
      {
        name: "GN",
        fuel: "GN",
        unit: "kNm³/h",
        lower: 0,
        upper: 48,
        alertLower: false,
        alertUpper: false,
      },
    ],
  },
];

export const priorizationModal = [
  {
    fuelName: "GCO",
    fuelNameLimit: "GCO",
    type1: "C ESP [Mcal/ton]",
    table1: "specific_consumption",
    type2: "VAZÃO [kNm³/h]",
    table2: "flow_rate",
    tableL: "VARIABLES",
    type3: "ORDEM ATUAL",
    table3: "current_order",
    type4: "PRIORIDADE",
  },
  {
    fuelName: "GAF",
    fuelNameLimit: "GAF",
    type1: "C ESP [Mcal/ton]",
    table1: "specific_consumption",
    type2: "VAZÃO [kNm³/h]",
    table2: "flow_rate",
    tableL: "VARIABLES",
    type3: "ORDEM ATUAL",
    table3: "current_order",
    type4: "PRIORIDADE",
  },
  {
    fuelName: "GAC",
    fuelNameLimit: "GAC",
    type1: "C ESP [Mcal/ton]",
    table1: "specific_consumption",
    type2: "VAZÃO [kNm³/h]",
    table2: "flow_rate",
    tableL: "VARIABLES",
    type3: "ORDEM ATUAL",
    table3: "current_order",
    type4: "PRIORIDADE",
  },
  {
    fuelName: "OL",
    fuelNameLimit: "OL",
    type1: "C ESP [Mcal/ton]",
    table1: "specific_consumption",
    type2: "VAZÃO [tol/h]",
    table2: "flow_rate",
    tableL: "VARIABLES",
    type3: "ORDEM ATUAL",
    table3: "current_order",
    type4: "PRIORIDADE",
  },
  {
    fuelName: "GN",
    fuelNameLimit: "GN",
    type1: "C ESP [Mcal/ton]",
    table1: "specific_consumption",
    type2: "VAZÃO [kNm³/h]",
    table2: "flow_rate",
    tableL: "VARIABLES",
    type3: "ORDEM ATUAL",
    table3: "current_order",
    type4: "PRIORIDADE",
  },
];

export const onDemandOptimizationModal = [
  {
    fuel: "GCO",
    variable1: "GCO",
    variable2: "GCO",
    total: 0,
  },
  {
    fuel: "GAF",
    variable1: "GAF",
    variable2: "GAF",
    total: 0,
  },
  {
    fuel: "GAC",
    variable1: "GAC",
    variable2: "GAC",
    total: 0,
  },
  {
    fuel: "Oleo",
    variable1: "Oleo",
    variable2: "Oleo",
    total: 0,
  },
  {
    fuel: "GN",
    variable1: "GN",
    variable2: "GN",
    total: 0,
  },
];

export const onDemandOptimizationModal2 = [
  {
    row: "ENERGIA [Mcal/h]",
    name: "energy",
  },
  {
    row: "CONS. ESP. [Mcal/ton]",
    name: "specific_thermal_consumptions",
  },
  {
    row: "PRODUÇÃO [ton/h]",
    name: "steam_production",
  },
];

export const fuelLimitsInitial = [
  {
    title: "VARIABLES",
    table: [
      {
        cells: [
          {
            equipment: "Caldeira 1",
            fuel: "GAC",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 5,
            upper2: 36,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C1VARIABLEGAC",
          },
          {
            equipment: "Caldeira 1",
            fuel: "GAF",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 25,
            upper2: 110,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C1VARIABLEGAF",
          },
          {
            equipment: "Caldeira 1",
            fuel: "GCO",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 3,
            upper2: 18,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C1VARIABLEGCO",
          },
          {
            equipment: "Caldeira 1",
            fuel: "OL",
            unit: "ton/h",
            lower1: 0,
            upper1: 0,
            lower2: 2,
            upper2: 12,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C1VARIABLEOL",
          },
          {
            equipment: "Caldeira 1",
            fuel: "GN",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 2,
            upper2: 12,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C1VARIABLEGN",
          }
        ],
        name: "Caldeira 1",
      },
      {
        cells: [
          {
            equipment: "Caldeira 2",
            fuel: "GAC",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 5,
            upper2: 36,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C2VARIABLEGAC",
          },
          {
            equipment: "Caldeira 2",
            fuel: "GAF",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 25,
            upper2: 110,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C2VARIABLEGAF",
          },
          {
            equipment: "Caldeira 2",
            fuel: "GCO",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 3,
            upper2: 18,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C2VARIABLEGCO",
          },
          {
            equipment: "Caldeira 2",
            fuel: "OL",
            unit: "ton/h",
            lower1: 0,
            upper1: 0,
            lower2: 2,
            upper2: 12,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C2VARIABLEOL",
          },
          {
            equipment: "Caldeira 2",
            fuel: "GN",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 2,
            upper2: 12,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C2VARIABLEGN",
          }
        ],
        name: "Caldeira 2",
      },
      {
        cells: [
          {
            equipment: "Caldeira 3",
            fuel: "GAC",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 5,
            upper2: 36,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C3VARIABLEGAC",
          },
          {
            equipment: "Caldeira 3",
            fuel: "GAF",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 25,
            upper2: 110,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C3VARIABLEGAF",
          },
          {
            equipment: "Caldeira 3",
            fuel: "GCO",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 3,
            upper2: 18,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C3VARIABLEGCO",
          },
          {
            equipment: "Caldeira 3",
            fuel: "OL",
            unit: "ton/h",
            lower1: 0,
            upper1: 0,
            lower2: 2,
            upper2: 12,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C3VARIABLEOL",
          },
          {
            equipment: "Caldeira 3",
            fuel: "GN",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 2,
            upper2: 12,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C3VARIABLEGN",
          }
        ],
        name: "Caldeira 3",
      },
      {
        cells: [
          {
            equipment: "Caldeira 4",
            fuel: "GAC",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 5,
            upper2: 36,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C4VARIABLEGAC",
          },
          {
            equipment: "Caldeira 4",
            fuel: "GAF",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 25,
            upper2: 110,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C4VARIABLEGAF",
          },
          {
            equipment: "Caldeira 4",
            fuel: "GCO",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 3,
            upper2: 18,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C4VARIABLEGCO",
          },
          {
            equipment: "Caldeira 4",
            fuel: "OL",
            unit: "ton/h",
            lower1: 0,
            upper1: 0,
            lower2: 2,
            upper2: 12,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C4VARIABLEOL",
          },
          {
            equipment: "Caldeira 4",
            fuel: "GN",
            unit: "kNm³/h",
            lower1: 0,
            upper1: 0,
            lower2: 2,
            upper2: 12,
            alertLower1: false,
            alertUpper1: false,
            alertLower2: false,
            alertUpper2: false,
            name: "C4VARIABLEGN",
          },
        ],
        name: "Caldeira 4",
      },
    ],
  },
];

export const flowLimitsInitial = [
  {
    title: "BOILERS",
    table: [
      {
        equipment: "Caldeira 1",
        unit: "ton/h",
        lower: 50,
        upper: 140,
        considered: true,
        alertLower: false,
        alertUpper: false,
        name: "C1",
      },
      {
        equipment: "Caldeira 2",
        unit: "ton/h",
        lower: 50,
        upper: 140,
        considered: true,
        alertLower: false,
        alertUpper: false,
        name: "C2",
      },
      {
        equipment: "Caldeira 3",
        unit: "ton/h",
        lower: 50,
        upper: 140,
        considered: true,
        alertLower: false,
        alertUpper: false,
        name: "C3",
      },
      {
        equipment: "Caldeira 4",
        unit: "ton/h",
        lower: 50,
        upper: 140,
        considered: true,
        alertLower: false,
        alertUpper: false,
        name: "C4",
      },
    ],
    maxDifference: [
      {
        value: 30,
        unit: "ton/h",
        name: "difference"
      },
    ],
  },
];
