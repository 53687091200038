import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, CircularProgress, Modal } from "@material-ui/core";

import { getOptimizationDemand } from "../../../../../../api";

import { AppState } from "../../../../../../store/rootReducer";
import { fuelValueDemandDecisionMaking } from "../../../../../../store/DecisionMaking/actions";

import { FuelDemandReal } from "../../../../../../models/OptimizationData";

import { GradientButton } from "../../../../../../components/GradientButton";
import { OnDemandOptimizationModal } from "../../../../../../components/OnDemandOptimizationModal";
import { ErrorAlert } from "../../../../../../components/ErrorAlert";

import { Container, Title, Table, Buttons, Input } from "./styles";

const style = {
  width: "100%",
  height: "100%",
  bgcolor: "#3A3A3A",
  overflow: "hidden auto",
  p: 2,
};

interface LimitOnDemandOptimizationProps {
  componentName: string;
  handleTab: (value: string) => void;
}

export const LimitOnDemandOptimization = ({
  componentName,
  handleTab,
}: LimitOnDemandOptimizationProps) => {
  const dispatch = useDispatch();

  const { pciCoeficient, fuelValue, flowValue, fuelDemand } = useSelector(
    (state: AppState) => state.decisionMaking
  );

  const [onDemandLimits, setOnDemandLimits] =
    useState<FuelDemandReal[]>(fuelDemand);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [loader, setLoader] = useState(false);

  const [onDemandData, setOnDemandData] = useState([]);

  const [error, setError] = useState(false);
  const [errorModelValidation, setErrorModelValidation] = useState({
    error: false,
    message: "",
  });

  const handleMinOnDemandLimits = (
    value: number,
    maxValue: number,
    fuel: string
  ) => {
    if (value > maxValue) {
      setOnDemandLimits(
        onDemandLimits.map((item) => {
          return {
            ...item,
            table: item.table.map((item) => {
              if (item.fuel === fuel) {
                return {
                  ...item,
                  lower: value,
                  alertLower: true,
                  alertUpper: false,
                };
              }
              return item;
            }),
          };
        })
      );
    } else {
      setOnDemandLimits(
        onDemandLimits.map((item) => {
          return {
            ...item,
            table: item.table.map((item) => {
              if (item.fuel === fuel) {
                return {
                  ...item,
                  lower: value,
                  alertLower: false,
                  alertUpper: false,
                };
              }
              return item;
            }),
          };
        })
      );
    }
  };

  const handleMaxOnDemandLimits = (
    value: number,
    minValue: number,
    fuel: string
  ) => {
    if (value < minValue) {
      setOnDemandLimits(
        onDemandLimits.map((item) => {
          return {
            ...item,
            table: item.table.map((item) => {
              if (item.fuel === fuel) {
                return {
                  ...item,
                  upper: value,
                  alertUpper: true,
                  alertLower: false,
                };
              }
              return item;
            }),
          };
        })
      );
    } else {
      setOnDemandLimits(
        onDemandLimits.map((item) => {
          return {
            ...item,
            table: item.table.map((item) => {
              if (item.fuel === fuel) {
                return {
                  ...item,
                  upper: value,
                  alertUpper: false,
                  alertLower: false,
                };
              }
              return item;
            }),
          };
        })
      );
    }
  };

  const clickOptimizationButton = () => {
    if (
      onDemandLimits[0].table.every(
        (item) => !item.alertLower && !item.alertUpper
      )
    ) {
      setLoader(true);
      dispatch(fuelValueDemandDecisionMaking(onDemandLimits));

      const pciCoefFinal = pciCoeficient.map((item) => {
        if (item.title === "PCI") {
          return {
            ...item,
            table: item.table.map((item) => {
              return {
                ...item,
                cells: item.cells.map((item) => {
                  return {
                    ...item,
                    name: item.name.includes("C4")
                      ? item.name.replace("OleoGN", "GN")
                      : item.name.replace("OleoGN", "Oleo"),
                  };
                }),
              };
            }),
          };
        } else {
          return {
            ...item,
            table: item.table.map((item) => {
              return {
                ...item,
                cells: item.cells
                  .map((item) => {
                    return {
                      ...item,
                      name: item.name.includes("C4")
                        ? item.name.replace("OleoGN", "GN")
                        : item.name.replace("OleoGN", "Oleo"),
                    };
                  })
                  .slice(0, item.cells.length - 1),
              };
            }),
          };
        }
      });

      const fuelValueFinal = fuelValue.map((item) => {
        return {
          ...item,
          table: item.table.map((item) => {
            return {
              ...item,
              cells: item.cells.map(({ name, lower1, upper1 , lower2, upper2 }) => {
                return {
                  name,
                  lower1,
                  upper1,
                  lower2,
                  upper2,
                };
              }),
            };
          }),
        };
      });

      const flowValueFinal = flowValue.map((item) => {
        return {
          ...item,
          table: item.table.map(({ name, upper, lower ,considered}) => {
            return {
              name,
              upper,
              lower,
              considered,
            };
          }),
        };
      });

      const fuelDemandRealFinal = onDemandLimits.map((item) => {
        return {
          ...item,
          table: item.table.map(({ name, upper, lower }) => {
            return {
              name,
              upper,
              lower,
            };
          }),
        };
      });

      const result = [
        pciCoefFinal[0],
        pciCoefFinal[1],
        fuelValueFinal[0],
        flowValueFinal[0],
        fuelDemandRealFinal[0],
      ];

      console.log("teste", result)

      getOptimizationDemand(JSON.stringify(result))
        .then((response) => {
          const { data } = response;
          if (!data[0].validation_model) {
            setLoader(false);
            setOpen(false);
            data[0].validations?.forEach((item: any) => {
              setErrorModelValidation({
                error: true,
                message: item.message,
              });
            });
            setError(false);
          } else {
            setOnDemandData(data);
            setLoader(false);
            setOpen(true);
            setError(false);
            setErrorModelValidation({
              error: false,
              message: "",
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          setError(true);
          setOpen(false);
          setErrorModelValidation({
            error: false,
            message: "",
          });
        });
    } else {
      setOpenAlert(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleAlertCloseError = () => {
    setError(false);
  };

  return (
    <Container>
      <Title>LIMITES POR DEMANDA</Title>
      <div>
        <Table>
          <thead>
            <tr>
              <th colSpan={3} className="title-table">
                DISPONIBILIDADE DOS COMBUSTÍVEIS
              </th>
            </tr>
            <tr>
              <th>COMBUSTÍVEL</th>
              <th>LIMITE INFERIOR</th>
              <th>LIMITE SUPERIOR</th>
            </tr>
          </thead>

          <tbody>
            {onDemandLimits.map((item) =>
              item.table.map((itemTable) => (
                <tr key={itemTable.fuel + itemTable.unit}>
                  <td>{itemTable.fuel === "Oleo" ? "Óleo" : itemTable.fuel}</td>
                  <td>
                    <Input
                      type="number"
                      error={itemTable.alertLower}
                      defaultValue={itemTable.lower}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleMinOnDemandLimits(
                          value,
                          itemTable.upper,
                          itemTable.fuel
                        );
                      }}
                    />
                    <span>{itemTable.unit}</span>
                  </td>
                  <td>
                    <Input
                      type="number"
                      error={itemTable.alertUpper}
                      defaultValue={itemTable.upper}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleMaxOnDemandLimits(
                          value,
                          itemTable.lower,
                          itemTable.fuel
                        );
                      }}
                    />
                    <span>{itemTable.unit}</span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <GradientButton
          style={{ width: "100%" }}
          onClick={() => clickOptimizationButton()}
        >
          {loader ? (
            <CircularProgress
              style={{ color: "white", width: "25px", height: "25px" }}
            />
          ) : (
            "OTIMIZAR"
          )}
        </GradientButton>
      </div>

      <Buttons>
        <GradientButton
          onClick={() => {
            dispatch(fuelValueDemandDecisionMaking(onDemandLimits));
            handleTab("otimização");
          }}
        >
          Voltar
        </GradientButton>
      </Buttons>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <OnDemandOptimizationModal
            data={onDemandData}
            handleClose={handleClose}
          />
        </Box>
      </Modal>

      {error && (
        <ErrorAlert
          open={error}
          setOpenAlert={handleAlertCloseError}
          message="Com esses parâmetros de entrada, não foi possível determinar uma solução factível. Por favor tente novamente com novos parâmetros."
        />
      )}

      {errorModelValidation.error && (
        <ErrorAlert
          open={errorModelValidation.error}
          setOpenAlert={() =>
            setErrorModelValidation({ error: false, message: "" })
          }
          message={errorModelValidation.message}
        />
      )}

      {openAlert && (
        <ErrorAlert
          open={openAlert}
          setOpenAlert={handleAlertClose}
          message="O limite superior deve ser maior que o limite inferior.
          Por favor verifique os campos em vermelho."
        />
      )}
    </Container>
  );
};
